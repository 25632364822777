import { createContext, useState, ReactNode, useMemo } from 'react';
import { MessageData } from '../interface/interface';

const messageInitialData = [
    {
        message: '',
        publisherId: '',
        cancelAlarmTrigger: false,
        isCancelAlarmActive: false,
        requestPoliceTrigger: false,
        whoRequestForPolice: false,
        publisherName: '',
        type: '',
        action: '',
        msgTime: '',
        contacts: [],
        clips: [],
        timeExtended: false,
        isFirstClip: false,
    },
];

interface MessageContextType {
    messageData: MessageData;
    updateMessageData: (data: MessageData) => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

interface MessageProviderProps {
    children: ReactNode;
}

const MessageProvider = ({ children }: MessageProviderProps) => {
    const [messageData, setMessageData] = useState<MessageData>(
        messageInitialData as MessageData,
    );

    const updateMessageData = (data: MessageData) => {
        setMessageData(data);
    };

    const contextValue = useMemo(() => {
        return {
            messageData,
            updateMessageData,
        };
    }, [messageData]);

    return (
        <MessageContext.Provider value={contextValue}>
            {children}
        </MessageContext.Provider>
    );
};

export { MessageProvider, MessageContext };
